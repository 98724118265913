
import { Component, Vue, Watch } from "vue-property-decorator";

import App from "@/app/modules/_global/classes/App";
import Rules from "@/app/modules/_global/classes/Rules";

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue")
  }
})
export default class ReceiveInvoicesComponent extends Vue {
  formData = {
    receive_via_email: this.userData.personal_profile.receive_via_email,
    invoice_email: this.userData.personal_profile.invoice_email ?? this.userData.user.email
  };

  formRules = {
    required: Rules.required,
    email: Rules.email
  };

  formValid = false;
  formUpdated = false;
  formErrors = null;

  @Watch("formData", { deep: true })
  onFormDataChanged(value: Record<string, unknown>) {
    const { receive_via_email: receive, invoice_email: email } = this.userData.personal_profile;
    this.formUpdated = value.receive_via_email != receive || value.invoice_email != email;
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  async receiveInvoicesViaEmail() {
    this.formErrors = null;

    this.$store
      .dispatch("profile/receiveInvoicesViaEmail", this.formData)
      .then(() => {
        App.init({ auth: true })
          .then(() => {
            this.$store.commit("snack/setSnack", {
              status: "success",
              text: this.$t("SNACKBAR.SUCCESS")
            });
          })
          .finally(() => (this.formUpdated = false));
      })
      .catch(error => {
        this.formErrors = error.response.data.errors;
      });
  }
}
